<template>
  <div class="indexHomePage page-gray" ref="indexHomePage">
    <div style="height: 1px"></div>
    <van-notice-bar
        v-if="announcementContent!==''"
        left-icon="volume"
        color="#D48232"
        background="#FDF5E0"
        mode="link"
        :text="announcementContent"
        @click="$router.push({path:'/notice',query:{schoolId:studentInfo.schoolid}})"
    />
    <!-- 学生列表 -->
<!--    <student-list v-if="studentArr && studentArr.length > 1" :student-arr.sync="studentArr" @input="choiceStudent"/>-->
    <!-- 学生列表 END -->
    <!-- 单学生页面 -->
    <single-student :student-info.sync="studentInfo" @input="choiceStudent"/>
    <!-- 轮播图 -->
    <van-swipe class="my-swipe" :autoplay="3000" v-if="uiConfig.mallHomeAd ==='1'" indicator-color="white">
      <van-swipe-item v-for="item in ad">
        <a :href="item.advurl">
          <img :src="item.advpic" alt="">
        </a>
      </van-swipe-item>
    </van-swipe>
    <!-- 轮播图 END -->

    <div class="activitiesOfGoods">
      <div class="banner">
        <img :src="timeLimitedData.imgPath?timeLimitedData.imgPath:require('./../../assets/images/index/xsyd.png')" alt="">
<!--        <img src="./../../assets/images/index/xsyd.png" alt="">-->
      </div>
      <div class="content">
        <!-- TODO 如果没有商品就展示下面这个empty组件 -->
        <empty
            v-show="timeLimitedData.emptyState && timeLimitedData.emptyState === '1' && timeLimitedData.products.length === 0"
            text="暂无限时预定商品，敬请期待..."
        />
        <!-- TODO 渲染商品 -->
        <div class="goodsList" v-if="timeLimitedData.displayMode==='1'">
          <div v-for="(item,index) in timeLimitedData.products"  :key="index">
            <goods-item :product.sync="item" :student-info.sync="studentInfo" type="vertical"></goods-item>
          </div>
        </div>
        <div class="goodsList" v-if="timeLimitedData.displayMode==='0'">
          <div v-for="(item,index) in timeLimitedData.products"  :key="index" style="width: 100%">
            <goods-item v-if="timeLimitedData.displayMode==='0'" :product.sync="item" :student-info.sync="studentInfo" type="cross"></goods-item>
          </div>
        </div>

        <div class="more" v-show="timeLimitedData.showHistProduct==='1'" @click="openLimitDialog(timeLimitedData.lmId)">更多历史商品</div>
      </div>
    </div>

    <div class="activitiesOfGoods">
      <div class="banner">
        <img :src="buyAnytimeData.imgPath?buyAnytimeData.imgPath:require('./../../assets/images/index/ssgm.png')" alt="">
<!--        <img src="./../../assets/images/index/ssgm.png" alt="">-->
      </div>
      <div class="content">
        <!-- TODO 如果没有商品就展示下面这个empty组件 -->
        <empty
            v-show="buyAnytimeData.emptyState && buyAnytimeData.emptyState === '1' && buyAnytimeData.products.length === 0"
            text="暂无随时购买商品，敬请期待..."
        />
        <!-- TODO 渲染商品 -->
        <div class="goodsList" v-if="buyAnytimeData.displayMode==='1'">
          <div v-for="(item,index) in buyAnytimeData.products" :key="index">
            <goods-item :product.sync="item" :student-info.sync="studentInfo" type="vertical"></goods-item>
          </div>
        </div>
        <div class="goodsList" v-if="buyAnytimeData.displayMode==='0'">
          <div v-for="(item,index) in buyAnytimeData.products"  :key="index" style="width: 100%">
            <goods-item v-if="buyAnytimeData.displayMode==='0'" :product.sync="item" :student-info.sync="studentInfo" type="cross"></goods-item>
          </div>
        </div>

        <div class="more" v-show="buyAnytimeData.showHistProduct==='1'" @click="openAnyTimeDialog(buyAnytimeData.lmId)">更多历史商品</div>
      </div>
    </div>

    <div class="activitiesOfGoods" v-for="(item,index) in otherLmData" :key="index">
      <div class="banner">
        <img :src="item.imgPath" alt="">
      </div>
      <div class="content">
        <!-- TODO 如果没有商品就展示下面这个empty组件 -->
        <empty
            v-if="item.emptyState && item.emptyState === '1' && item.products.length === 0"
            text="暂无购买商品，敬请期待..."
        />
        <!-- TODO 渲染商品 -->
        <div class="goodsList" v-if="item.displayMode==='1'">
          <div v-for="(sku,index) in item.products" :key="index">
            <goods-item  :product.sync="sku" :student-info.sync="studentInfo" type="vertical"></goods-item>
          </div>
        </div>
        <div class="goodsList" v-if="item.displayMode==='0'">
          <div v-for="(sku,index) in item.products"  :key="index" style="width: 100%">
            <goods-item  :product.sync="sku" :student-info.sync="studentInfo" type="cross"></goods-item>
          </div>
        </div>

        <div class="more" v-if="otherLmData.showHistProduct==='1'" @click="openAnyTimeDialog(otherLmData.lmId)">更多历史商品</div>
      </div>
    </div>


    <div class="handler" v-if="null!=shop">
      <div class="czzn">
        <img v-show="shop.type==='2'" :src="shop.operationGuidePic" alt="" @click="openCzznDialog">
        <a v-show="shop.type==='1'" :href="shop.operationGuideUrl">
          <img  :src="shop.operationGuidePic" alt="">
        </a>
      </div>

      <van-dialog v-model="czznShow" title="操作指南" :showCancelButton="false" show-cancel-button>
        <div style="height: 400px;overflow: scroll">
          <van-image
              :src="shop.operationGuideUrl"
          />
        </div>

<!--        <van-icon class="closeIcon" @click="popupShow = false" :size="px2rem(26)" name="close" color="#fff" />-->
      </van-dialog>

      <div class="zjzdyfx">
        <img :src="shop.parentEnvelopePic" alt="" @click="openZjzdyfxDialog">
      </div>

      <van-dialog v-model="zjzdyfxShow" title="致家长的信" :showCancelButton="false" show-cancel-button>
        <div :style="{ backgroundImage: `url(${shop.parentEnvelopeBackground})`
        ,'background-repeat':'no-repeat'
        ,'background-size':'100% 100%'
        ,'background-position': 'center center'
        ,overflow: 'scroll'
        ,height: '370px'}"  v-html="shop.parentEnvelopeContent">
        </div>
        <van-icon class="closeIcon" @click="popupShow = false" :size="px2rem(26)" name="close" color="#fff" />
      </van-dialog>
    </div>

<!--    广告弹窗-->
    <van-dialog v-model="popupShow" :title="popupTitle" show-cancel-button>
      <div class="popupContent" v-html="popupContent">
      </div>
      <van-icon class="closeIcon" @click="popupShow = false" :size="px2rem(26)" name="close" color="#fff" />
    </van-dialog>

<!--    <div class="activitiesOfGoods">
      <div class="banner">
        <img src="./../../assets/images/index/cjtj.png" alt="">
      </div>
      <div class="content">
        &lt;!&ndash; TODO 渲染商品 &ndash;&gt;
        <div class="goodsList">
          <goods-item v-for="item in 2" type="vertical"></goods-item>
        </div>
      </div>
    </div>-->

    <van-swipe class="ad" :autoplay="3000" indicator-color="white" v-show="uiConfig.jfMallAd ==='1'">
      <van-swipe-item v-for="item in jfAd">
        <a :href="item.advurl">
          <img :src="item.advpic" alt="">
          <!--          <img src="./../../assets/images/index/test/banner.png" alt="">-->
        </a>
      </van-swipe-item>
    </van-swipe>


    <!-- 积分商城  这是一个全局的组件， 建议把获取积分商品的接口写在这个组件里面， 因为其他地方也会用到 -->
<!--    <integral-mall v-show="uiConfig.jfMallFlag ==='1'" />-->

    <!-- 售后服务 -->

    <div class="after-sales" @click="onAfterSalesClick">
      <img src="./../../assets/images/index/sh.png" alt="">

      <div class="content">
        <p>厂家售后联系方式</p>
        <a>立即咨询</a>
      </div>
    </div>
    <page-footer/>

    <!-- 这两个组件貌似相似度百分之90以上， 但是我还是分成两个了， 怕有什么不同的处理，
     如果可以的话 还是一个方便点 -->
    <more-limit-goods ref="MoreLimitGoods"/>
    <more-any-time-goods ref="MoreAnyTimeGoods"/>


    <div class="fixed_right_menu">
      <div class="item" @click="onAfterSalesClick">
        <img src="./../../assets/images/index/dianhua.png" class="dh" alt="">
      </div>
      <div class="item" @click="customServer">
        <img src="./../../assets/images/index/kefu.png" class="kf" alt="">
      </div>
      <transition name="el-zoom-in-top">
        <div class="item" @click="toTop" v-if="showTopBtn">
          <img src="./../../assets/images/index/gengduo.png" class="gd" alt="">
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import goodsItem from '@/components/goodsItem' // 商品卡片
// import IntegralMall from '@/components/integralMall' // 积分商城
import pageFooter from '@/components/pageFooter' // 底部骄阳
// import StudentList from './studentList.vue' // 学生列表

import MoreLimitGoods from './moreLimitGoods.vue' // 更多限时购历史
import MoreAnyTimeGoods from './moreAnyTimeGoods.vue'
import Vue from 'vue';
import {mapState, mapMutations, mapGetters} from 'vuex'
import SingleStudent from "./singleStudent.vue";

export default {
  name: "ShopHomePage",
  components: {
    SingleStudent,
    goodsItem,
    // IntegralMall,
    pageFooter,
    // StudentList,
    MoreLimitGoods,
    MoreAnyTimeGoods
  },
  data() {
    return {
      id:'',
      showTopBtn: false,
      studentArr: [],
      studentInfo: {},
      //公告内容
      announcementContent:"",
      //弹窗内容
      popupContent:"",
      //弹窗标题
      popupTitle:"",
      //首页广告
      ad:[],
      //限时购
      timeLimitedData: {},
      //随时购
      buyAnytimeData:{},
      //其他栏目数据
      otherLmData:[],
      //首页积分广告
      jfAd:[],
      //操作指南是否显示
      czznShow:false,
      //致家长的一封信是否展示
      zjzdyfxShow:false,
      //弹窗广告
      popupShow:false,
    }
  },
  created() {
    // this.id = this.$route.query.id;
    // this.studentArr = this.getStudentArr();
    // if(this.id){
    //   this.getStudent();
    //   //添加默认学生
    //   this.$http.post(this.baseMallUrl + `/h5_mall/switchDefaultStudents/${this.id}`, {
    //   }, {emulateJSON: true}).then(() => {
    //     // 操作成功
    //   })
    // }else if(this.studentArr.length === 0){
    //   this.queryIsStudent();
    // }else{
    //   this.studentInfo = this.getStudentInfo();
    //   this.queryProduct();
    // }
    this.queryIsStudent();
  },
  activated() {
    // this.studentInfo = this.getStudentInfo();
    this.queryIsStudent();
  },
  computed:{
    ...mapState(['shop','afterSalesSet','uiConfig']),
  },
  methods: {
    ...mapMutations(['setStudentArr','setStudentInfo']),
    ...mapGetters(['getStudentArr','getStudentInfo']),
    queryIsStudent() {
      this.$http.get(this.baseMallUrl + `/buyergrade/getStudentInfoByUserId/${Vue.prototype.userId}`).then(res => {
        this.studentArr = res.data;
        this.setStudentArr(res.data);
        res.data.forEach((item,index)=>{
          if(item.isDefault){
            this.studentInfo = item;
          }else if( res.data.length-1 === index){
            this.studentInfo = res.data && res.data[0];
          }
        })
        this.setStudentInfo(this.studentInfo);
        if (res.data.length === 0) {
          this.$router.replace({path: '/enterSchoolCode'});
        }else{
          this.queryProduct();
        }

      })
    },
    //查询学生信息（新更改内容）暂停使用
    getStudent(){
      this.$http.get(this.baseMallUrl + `/h5_mall/queryStudentInfo/${this.id}`).then(res => {
        this.studentInfo = res.data;
        this.setStudentInfo(this.studentInfo);
        this.queryProduct();
      })
    },
    //查询商品
    queryProduct(){
      //查询商品
      this.$http.get(this.baseMallUrl + `/h5_mall/homePageDataDisplay/${Vue.prototype.deptId}/${this.studentInfo.schoolid}/${this.studentInfo.gradeId}`).then(res => {
        let data = res.data;
        let broadContentVoList = data.broadContentVoList;
        if(broadContentVoList && broadContentVoList.length>0){
          let arr = broadContentVoList.filter((item)=>{
            return item.typeflag=='1'
          });
          this.popupContent = arr && arr.length>0 && arr[0].content || "";
          this.popupTitle = arr && arr.length>0 && arr[0].title || "";
          if(this.popupContent !== ""){
            this.popupShow = true;
          }
          let arr2 = broadContentVoList.filter((item)=>{
            return item.typeflag=='2'
          });
          this.announcementContent = arr2 && arr2.length>0 && arr2[0].publicDisplayContent || "";
        }
        this.ad = data.h5AdvplaceVos;//广告图
        this.jfAd = data.h5JfAdvplaceVos;//积分广告图
        let productIndexMobileResultVos = data.productIndexMobileResultVos;//商品数据
        if(productIndexMobileResultVos && productIndexMobileResultVos.length>0){
          this.buyAnytimeData = productIndexMobileResultVos.filter((item)=>{return item.lmtype === 'lm_02'})[0];//随时购
          this.timeLimitedData = productIndexMobileResultVos.filter((item)=>{return item.lmtype === 'lm_01'})[0];//限时购
          this.otherLmData = productIndexMobileResultVos.filter((item)=>{return item.lmtype != 'lm_01' && item.lmtype != 'lm_02'});
        }
      })
    },
    openLimitDialog(lmId) {
      this.$refs.MoreLimitGoods.showPop(lmId,this.studentInfo.schoolid)
    },
    openAnyTimeDialog(lmId) {
      this.$refs.MoreAnyTimeGoods.showPop(lmId,this.studentInfo.schoolid)
    },
    openCzznDialog(){
      this.czznShow =true;
    },
    openZjzdyfxDialog(){
      this.zjzdyfxShow = true;
    },

    onAfterSalesClick() {
      window.location.href = `tel:${this.afterSalesSet.phone}`;
    },
    customServer() {
      // 设置要跳转到的 URL
      var urlToRedirect = 'https://work.weixin.qq.com/kfid/kfcd335dea3402369b8'; // 将此处替换为目标网址

    // 在新页面中加载指定的 URL
      window.location.href = urlToRedirect;
    },
    toTop() {
      // 由快到慢  （每次开启定时器都重新计算速度）
      var timer = setInterval(function () {
        let osTop = document.querySelector('.content-body').scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.querySelector('.content-body').scrollTop = osTop + ispeed;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    onScroll() {
      this.showTopBtn = document.querySelector('.content-body').scrollTop > document.body.offsetHeight
    },
    choiceStudent(studentInfo) {
      this.studentInfo = studentInfo;
      this.setStudentInfo(this.studentInfo);
      this.queryProduct();
      //添加默认学生
      this.$http.post(this.baseMallUrl + `/h5_mall/switchDefaultStudents/${studentInfo.id}`, {
      }, {emulateJSON: true}).then(() => {
        //操作成功 获取学校信息
       this.queryIsStudent();
      })
    },
    // 阻止返回
    preventBack(event) {
      history.pushState(null, null, document.URL);
      event.preventDefault();
    }
  },
  destroyed() {
    // document.querySelector('.content-body').removeEventListener('scroll', this.onScroll)
  },

  mounted() {
    this.onScroll()
    // document.querySelector('.content-body').addEventListener('scroll', this.onScroll)
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', this.preventBack);
  },
  // 在beforeDestroy钩子中移除
  beforeDestroy() {
    // window.removeEventListener('popstate', this.preventBack);
  },
}

</script>
<style lang="less" scoped>

::v-deep .van-notice-bar {
  margin: 10px 10px 0;
  box-shadow: 2px 0px 20px 0px rgba(210, 197, 162, 0.3000);
  border-radius: 3px;
  height: 32px;
}

::v-deep .van-notice-bar__left-icon,
::v-deep .van-notice-bar__right-icon {
  color: #EF9B39;
}

.my-swipe {
  margin: 10px;

  img {
    height: 178px;
    border-radius: 5px;
    width: 100%;
  }
}

// ::v-deep .van-swipe__indicator.van-swipe__indicator--active {
//     width: 25px;
//     height: 5px;
//     background: #FFFFFF;
//     border-radius: 3px;
// }

.activitiesOfGoods {
  padding: 0 10px 1px 10px;

  .banner {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content {
    margin-top: -14px;
    position: relative;
    z-index: 3;

    .goodsList {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 1px;
      justify-content: space-between;
      padding-bottom: 10px;

    }

    .more {
      border: 1px solid #C7C7C7;
      border-radius: 4px;
      display: block;
      width: 100px;
      margin: 2px auto 19px auto;


      font-size: 12px;
      color: #999999;
      line-height: 1;

      text-align: center;
      padding: 6px 0;
    }
  }


}

.handler {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px 17px 10px;

  > div {
    img {
      width: 172px;
    }
  }
}

.ad {
  margin: 0 10px;

  img {
    width: 100%;
  }
}

.after-sales {
  height: 90px;
  margin: 10px 10px 0 10px;
  position: relative;

  img {
    height: 90px;
    width: 100%;
  }

  .content {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      line-height: 1;
      font-size: 15px;
      font-weight: 500;
      color: #FEFEFE;
      text-shadow: 0px 0px 2px rgba(97, 97, 97, 0.28);
    }

    a {
      margin-top: 10px;
      padding: 5px 13px;
      background: #D53A26;
      border-radius: 100px;
      line-height: 19px;

      font-size: 10px;
      font-weight: 400;
      color: #FEFEFE;
      line-height: 10px;
    }
  }
}

.fixed_right_menu {
  position: fixed;
  right: 10px;
  top: 314px;
  z-index: 5;

  .item {
    width: 40px;
    height: 40px;
    background: #FEFEFE;
    box-shadow: 0px 1px 5px 0px rgba(177, 177, 177, 0.3000);
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;

    img.dh {
      width: 19px;
      height: 21px;
    }

    img.kf {
      height: 24px;
      width: 23px;
    }

    img.gd {
      height: 11px;
      width: 20px;
    }
  }
}


</style>

<style lang="less">
.popupContent {
  width: 100%;
  margin: auto;
  position: relative;
  height: 50vh;
  overflow: auto;
  img {
    width: 100%;
  }
  font-size: 14px;
  padding: 15px;
}
</style>
